@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply text-gray-100 bg-logseq-800;

  &.is-nav-open {
    overflow: hidden;
  }
}

.app-container {
  @apply w-full flex-1 overflow-hidden;
}

.ani-slide-in-from-bottom {
  @apply animate-in fade-in-0 duration-1000 slide-in-from-bottom-14;

  visibility: visible !important;
}

.ani-slide-in-from-top {
  @apply animate-in fade-in-0 duration-1000 slide-in-from-top-14;

  visibility: visible !important;
}

.ani-slide-in-from-left {
  @apply animate-in fade-in-0 duration-1000 slide-in-from-left-14;

  visibility: visible !important;
}

.ani-slide-in-from-right {
  @apply animate-in fade-in-0 duration-1000 slide-in-from-right-14;

  visibility: visible !important;
}

.ani-zoom-in {
  @apply animate-in zoom-in duration-1000 fade-in-0;

  visibility: visible !important;
}

.ani-fade-in {
  @apply animate-in fade-in duration-1000;

  visibility: visible !important;
}

.glass-card {
  @apply bg-gradient-to-b from-white/40 to-logseq-800/5 rounded-md;

  visibility: hidden;

  &-inner {
    @apply backdrop-blur-5xl bg-logseq-700/50 rounded-md p-3 w-full;

    margin: .5px;
    padding-top: 15px;
  }
}

.glass-btn {
  @apply backdrop-blur-sm bg-logseq-500/20 px-2 py-2 rounded-md border border-gray-500/30
  select-none active:opacity-70 cursor-pointer hover:border-gray-500/50 flex justify-center items-center;
}

.app-logo-link {
  display: inline-block;
  width: 32px;
  height: 32px;
  background-image: url("./logo-with-border.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  right: -5px;
  top: 1px;

  border-radius: 8px;
  box-shadow: 0 4px 32px 0 rgba(133, 200, 200, 0.3);
}

.app-headbar {
  @apply w-full px-6 relative;

  .links-group {
    @apply flex flex-1 space-x-8 whitespace-nowrap;

    a {
      @apply text-logseq-100 sm:text-[#A4B5B6] text-base sm:text-lg hover:text-gray-300;

      &.app-link-active {
        @apply text-gray-300 font-semibold sm:opacity-100;
      }
    }
  }

  .right-group {
    &-inner {
      @apply invisible absolute left-0 top-16 w-full bg-logseq-800
      flex-col space-x-0 items-center px-2 pb-6 opacity-0 transition-opacity duration-300
      sm:flex sm:flex-row sm:top-0 sm:sticky sm:opacity-100 sm:visible
      sm:px-0 sm:space-x-6 sm:pb-0 sm:translate-y-0;

      z-index: -1;

      @screen sm {
        z-index: 1;
      }
    }

    &:before {
      @apply sm:hidden z-40;

      display: none;
      content: " ";
      height: 100vh;
      width: 100vw;
      background: rgba(7, 7, 7, 0.43);
      position: fixed;
      left: 0;
      top: 90px;
    }

    &.is-active {
      .right-group-inner {
        @apply visible flex opacity-100 z-40;
      }

      &:before {
        display: block;
      }
    }
  }
}

.app-link-active {
}

.app-logo {
  @apply flex items-center justify-center
  bg-logseq-700 overflow-hidden border-logseq-800
  shadow-2xl shadow-logseq-300 p-3 rounded-3xl;

  img {
    @apply translate-y-0.5;
  }
}
